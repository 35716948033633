import {domainUrl} from "../../helper";

export const schemaNavLinks = (dbName: string, asPath: string, menuList: any[]) => {
    return {
        "@context": "https://schema.org/",
        "@type": "Menu",
        url: domainUrl(dbName + asPath),
        "name": "MenuItems",
        "description": "Company menu items description",
        "hasMenuSection": menuList.map((item: any, index: number) => ({
            "@type": "MenuSection",
            "position": index + 1,
            "hasMenuItem": {
                "@type": "MenuItem",
                "name": item?.name,
            },
        })),

    };
}