import React, {FC} from 'react';
import Head from "next/head";
import handleMouseEnter from "./handleMouseEnter";
import {LanguagesType, linksListProps as IProps} from "./types";
import Link from "next/link";
import dynamic from "next/dynamic";
import cls from "../header.module.scss"
import {ArrowRoundedDown9x6Svg} from "../../../svg";

const NavMenu = dynamic(() => import('../Menu/NavMenu'));

const LinksList: FC<IProps> = (
    {
        schemaLinks,
        classes,
        locale,
        item,
        realPath,
        hasChildren
    }
) => {
    return (
        <React.Fragment>
            <Head>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{__html: JSON.stringify(schemaLinks)}}
                />
            </Head>
            <li
                className={classes}
                onMouseEnter={event => handleMouseEnter(event, locale as keyof LanguagesType)}
            >
                <Link
                    href={(item.url_key || item.slug && `/${realPath}page/${item.slug}`) || ""}
                    prefetch={false}
                >
                    {
                        item.new_tab
                            ? <a target="_blank">
                                {item.name}
                                {hasChildren && <ArrowRoundedDown9x6Svg/>}
                            </a>
                            : <a>
                                {item.name}
                                {hasChildren && <ArrowRoundedDown9x6Svg/>}
                            </a>
                    }

                </Link>
                {hasChildren && (
                    <div className={`${cls["nav-links__menu"]}`}>
                        <NavMenu items={item.children} realPath={realPath}/>
                    </div>
                )}
            </li>
        </React.Fragment>
    )
}

export default LinksList;
