import React from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import Link from "next/link";
import {FormattedMessage} from "react-intl";
import {useRouter} from "next/router";
import {rightUrl} from "../../../services/utils";
import {IState} from "../../../types/state";
import LinksList from "../NavLinks/LinksList";
import {IProps} from "../../../types/homePageTypes";
import cls from "../header.module.scss"
import {schemaNavLinks} from "../../schemaorg";

function NavLinks({pageProps}: { pageProps?: IProps }) {
    const router = useRouter();
    const menuList = useSelector((state: IState) => state.general.menuList);
    const locale = useSelector((state: IState) => state.locale.code);
    let realPath = router.locale !== 'catchAll' ? router.locale + '/' : '';
    const hasBlog = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_blog_active?.value)))

    const schemaLinks = schemaNavLinks(pageProps?.dbName as string, router.asPath, menuList)

    const navLinks = router.asPath === "/" ? pageProps?.menus : menuList
    const linksList: JSX.Element[] = navLinks
        ?.filter((item: { type: string }) => item.type === "header")
        ?.map((item: any, index: number) => {
                const hasChildren = item.children && item.children.length > 0;
                const classes = classNames(cls["nav-links__item"],
                    hasChildren && cls["nav-links__item--with-submenu"]
                );

                return (
                    <LinksList
                        key={index}
                        schemaLinks={schemaLinks}
                        realPath={realPath}
                        classes={classes}
                        locale={locale}
                        item={item}
                        hasChildren={hasChildren}
                    />
                );
            }
        );

    let showBlog = pageProps?.isBlogActive && router.asPath === "/" || router.asPath !== "/" && hasBlog

    return (
        <ul className={`${cls["nav-links__list"]}`}>
            {linksList}
            {
                showBlog &&
                <li className={cls["nav-links__item"]}>
                    <Link href={rightUrl(`/blog`, router)} prefetch={false}>
                        <a>
                            <FormattedMessage id="blog" defaultMessage="Blog"/>
                        </a>
                    </Link>
                </li>
            }
        </ul>
    );
}

export default NavLinks;
