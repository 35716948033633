// application
const enMessages = require('./messages/en.json');
const ruMessages = require('./messages/ru.json');
const hyMessages = require('./messages/hy.json');

export default {
    en: {
        messages: enMessages,
        direction: 'ltr',
    },
    ru: {
        messages: ruMessages,
        direction: 'ltr',
    },
    hy: {
        messages: hyMessages,
        direction: 'ltr',
    }
};
